import React, {Component} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {base, appDatabasePrimaryFunctions} from '../../base';
import { confirmAlert } from '../utils/react-confirm-alert';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Loading from '../utils/Loading';
import '../../styles/css/Home.css';
import '../../styles/css/ConfirmAlertCustom.css';
import '../../styles/css/main.css';
import '../../styles/css/CreatorAwards.css';
import {validateEmail, sanitizeEmail} from "../utils/HelpfulFunction";
import {Textfit} from "react-textfit";

class Season_Long extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: sessionStorage.getItem('userEmail') || false,
            inputtedEmail: "",
            gameActive: false,
            seenVideo: false,
            tenantRules: {},
            currentPick: {},
            winner: "",
            racers: [],
            selectedItem: 0,
            videoState: 0,
            status: 0,
            timeToSendEmails: null,
            videoDuration: "0:00",
            width: 0,
            height: 0,
            highlightedPick: false,
            questionAnswered: false,
            formFilledOut: false,
            userAnswers: [],
            alreadyUpdate: "",
            currentGameId: null,
            locationChecking: true,
            loading: true,
            startConfetti: false,
            downloadingImage: false,
            downloadedFile: null,
            modal: false,
            campaignPick: {},
            modalSupport: false,
            imageLoading: true
        };
        this.logout = this.logout.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.toggleRules = this.toggleRules.bind(this);
        this.toggleSupport = this.toggleSupport.bind(this);
    }

    componentDidMount() {
        this.handleSeasonLongMethods();
        this.tenantRulesRef = base.bindToState(`tenantRules`, {
            context: this,
            state: 'tenantRules',
        });
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.props.loadPage();
    }

    handleSeasonLongMethods(){
        const userEmail = this.state.userEmail;
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        let base64EncodedEmail = btoa(userEmail);
        if(!validateEmail(userEmail)){
            base64EncodedEmail = userEmail;
        }
        this.currentPickRef = base.bindToState('campaignPicks/'+base64EncodedEmail, {
            context: this,
            state: 'campaignPick'
        })
        this.allRacersListRef = base.bindToState('racerList', {
            context: this,
            state: 'allRacersList',
            asArray: true
        })
        this.setState({
            loading: false
        })
    }

    componentWillUnmount() {
        base.removeBinding(this.allRacersListRef);
        base.removeBinding(this.tenantRulesRef);
        base.removeBinding(this.currentPickRef);
    }

    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    toggleRules() {
        this.setState({
            modalRules: !this.state.modalRules,
        });
    }

    toggleSupport(){
        this.setState({
            modalSupport: !this.state.modalSupport,
        });
    }

    logout(){
        sessionStorage.removeItem('userEmail');
        sessionStorage.removeItem('verifiedAge');
        sessionStorage.removeItem('birthday');
        sessionStorage.removeItem('locationPermissions');
        this.setState({
            userEmail:false
        })
    }

    onClickCampaignPick(pickClicked){
        if(this.state.campaignPick.pickid){
            return
        }
        this.setState({
            highlightedPick: pickClicked.id || pickClicked.key
        })
    }

    onSubmitCampaignAnswer(){
        const tenantVariables = this.props.variables || {};
        const seasonLongHeaderPick = tenantVariables.selectCharacterCampaignHeader || "Select This Character?";
        const seasonLongBodyPick = tenantVariables.selectCharacterCampaignBody || "Once you choose you're locked in & can't switch.";
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        const vm = this;
        confirmAlert({
            title: seasonLongHeaderPick,
            variables: vm.props.variables,
            message: seasonLongBodyPick,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        if(isMlbApp){
                               this.submitEmail();
                        } else {
                            vm.setState({
                                modal: true,
                            })
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => console.log("dismiss")
                }
            ]
        })
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    async submitEmail(){
        const tenantVariables = this.props.variables || {};
        const highlightedPick = this.state.highlightedPick;
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        const agree_to_promotionRef = this.refs.agree_to_promotion.checked;
        let confirmMandatoryRef = false;
        if(!isMlbApp){
            confirmMandatoryRef = this.refs.agree_to_rules_regs.checked;
        }
        const inputtedEmail = this.state.inputtedEmail.trim().toLowerCase() || "";
        let base64EncodedEmail = btoa(inputtedEmail);
        if(isMlbApp){
            base64EncodedEmail = sessionStorage.getItem('userEmail') || false;
            if(!base64EncodedEmail){
                confirmAlert({
                    variables: this.props.variables,
                    title: 'Something Went Wrong',
                    message: "Try quitting out of the mlb app and try again!",
                    buttons: [
                        {
                            label: 'OK',
                        }
                    ]
                })
                return
            }
        }
        const vm = this;
        if(!isMlbApp && !validateEmail(inputtedEmail)){
            confirmAlert({
                title: "Error",
                variables: vm.props.variables,
                message: "Not a valid email",
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => console.log("dismiss")
                    }
                ]
            })
            return
        }
        let sanitizedEmail = "";
        if(inputtedEmail && !isMlbApp){
            try {
                sanitizedEmail = sanitizeEmail(inputtedEmail);
            } catch (e) {
                console.log(e)
            }
            base64EncodedEmail = btoa(inputtedEmail);
        }

        if(!tenantVariables.noMandatoryTermsAndConditions && !confirmMandatoryRef && !isMlbApp){
            confirmAlert({
                variables: this.props.variables,
                title: 'Missing Info',
                message: "Please agree to rules and regulations!",
                buttons: [
                    {
                        label: 'OK',
                    }
                ]
            })
            return;
        }
        const userObject = {};
        userObject['lastSignIn'] = new Date().getTime();
        userObject['email'] = inputtedEmail;
        userObject['sanitizedEmail'] = sanitizedEmail;
        userObject['optIn'] = agree_to_promotionRef || false;
        userObject['uid'] = base64EncodedEmail;
        const userSelectionObject = {};
        userSelectionObject.pickid = highlightedPick;
        userSelectionObject.uid = base64EncodedEmail;
        userSelectionObject.timeStamp = Date.now();
        const postToUserPicks = {};
        postToUserPicks["/campaignPicks/" + base64EncodedEmail] = userSelectionObject;
        postToUserPicks["/users/" + base64EncodedEmail] = userObject;
        const hasAnswer = await appDatabasePrimaryFunctions.ref("/campaignPicks/" + base64EncodedEmail).once("value").then(function(snapshot) {
            if(snapshot.exists()){
                return snapshot.val();
            } else {
                return null;
            }
        })
        if(!hasAnswer){
            appDatabasePrimaryFunctions.ref().update(postToUserPicks, function(error){
                if(!error){
                    vm.setState({
                        highlightedPick: false
                    }, ()=>{
                        const afterPickCampaignHeader = tenantVariables.afterPickCampaignHeader || "Good Luck!";
                        const afterPickCampaignBody = tenantVariables.afterPickCampaignBody || "We'll see you at the finish line.";
                        confirmAlert({
                            title: afterPickCampaignHeader,
                            variables: vm.props.variables,
                            message: afterPickCampaignBody,
                            buttons: [
                                {
                                    label: "Ok",
                                    onClick: () => {
                                        vm.setState({
                                            modal:false,
                                            campaignPick: userSelectionObject
                                        })
                                        if(base64EncodedEmail){
                                            appDatabasePrimaryFunctions.ref('users/' + base64EncodedEmail + '/signUpTime').set(new Date().getTime())
                                                .catch(function(error){
                                                    console.log(error)
                                                })
                                        }
                                    }
                                }
                            ]
                        })
                    })
                } else {
                    console.log(error);
                    confirmAlert({
                        title: 'There was an error',
                        variables: vm.props.variables,
                        message: error.message,
                        buttons: [
                            {
                                label: 'OK',
                            }
                        ]
                    })
                }
            })
        } else {
            confirmAlert({
                variables: this.props.variables,
                title: 'Already Submitted Pick',
                message: "You have already made a season long pick.  You cannot make another one at this time",
                buttons: [
                    {
                        label: 'OK',
                    }
                ]
            })
        }
    }

    renderSeasonLongScreen(){
        const tenantVariables = this.props.variables || {};
        const tenantRules = this.state.tenantRules || {};
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        const racers = this.state.allRacersList || [];
        const whoWillWinText = tenantVariables.campaignLongInfoText || "Pick Your Season Long Pick";
        const link = tenantRules.rulesAndRegsLink;
        const mlbPrivacyPolicyLink = tenantRules.mlbPrivacyPolicyLink || "https://www.mlb.com/app/ballpark/official-information/privacy-policy";
        const rulesRegsText = tenantRules.rulesAndRegsText || "Please agree to the Rules and Regulations";
        const primaryColor = tenantVariables.primaryColor || "black";
        const secondaryColor = tenantVariables.secondaryColor || "white";
        const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
        let rulesShowInAppPopUpHeader = tenantRules.rulesShowInAppPopUpHeader;
        let rulesShowInAppPopUpText = tenantRules.rulesShowInAppPopUpText;
        let rulesPopUpText = tenantRules.rulesPopUpText;
        let rulesPopUpHeader = tenantRules.rulesPopUpHeader;
        const promotionText = tenantVariables.promotionText || "Check To Opt-In To More Offers From Us";
        const rulesInAppButtonText = tenantRules.rulesInAppButtonText || "Rules & Regs";
        const supportHeader = tenantRules.supportHeader || "NEED SUPPORT?";
        const supportText = tenantRules.supportText || "Email customerservice@website.mlb.com with your issue and we'll be in touch!";
        const rulesShowInApp = tenantRules.rulesShowInApp || isMlbApp || false;
        if(rulesShowInApp) {
            if(!rulesShowInAppPopUpText && rulesPopUpText){
                rulesShowInAppPopUpText = rulesPopUpText;
            }
            if(!rulesShowInAppPopUpHeader && rulesPopUpHeader){
                rulesShowInAppPopUpHeader = rulesPopUpHeader;
            }
        }
        return(
            <div className="flex-content-container-home">
                <div className="intro-container-home">
                    <div className="grid-wrapper">
                        {!this.state.imageLoading &&
                            <div className="flex-header-home" style={{marginTop:10, justifyContent: "space-between", maxWidth: "100%", minWidth: 280}}>
                                {isMlbApp &&
                                    <div style={{width: "25%", height: 38, color: logOutButtonColor, borderColor: logOutButtonColor, border: "solid 1px", borderRadius: 5, flexGrow:1, maxWidth: "25%"}}>
                                        <button className="btn" style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => { this.toggleSupport() }}>
                                            <Textfit mode="single" max={16} style={{overflowX: "hidden"}}>
                                                Support
                                            </Textfit>
                                        </button>
                                    </div>
                                }
                                {isMlbApp &&
                                    <div style={{ width: "25%", color: logOutButtonColor, borderColor: logOutButtonColor, height: 38, border: "solid 1px", borderRadius: 5, flexGrow:1, maxWidth: "25%"}}>
                                        <button className="btn" style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => {window.open("https://www.mlb.com/app/ballpark/official-information/terms-of-use", "_blank")}}>
                                            <Textfit mode="single" max={16} style={{overflowX: "hidden"}}>
                                                MLB TOU
                                            </Textfit>
                                        </button>
                                    </div>
                                }
                                {isMlbApp &&
                                    <div style={{ width: "25%", height: 38, color: logOutButtonColor, borderColor: logOutButtonColor, border: "solid 1px", borderRadius: 5, flexGrow:1, maxWidth: "25%"}}>
                                        <button className="btn" style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => { window.open(mlbPrivacyPolicyLink, "_blank") }}>
                                            <Textfit mode="single" max={16} style={{overflowX: "hidden"}}>
                                                MLB Privacy Policy
                                            </Textfit>
                                        </button>
                                    </div>
                                }
                                <div style={{visibility: rulesShowInApp ? "visible":"hidden", width: "25%", height: 38, color: logOutButtonColor, borderColor: logOutButtonColor, border: "solid 1px", borderRadius: 5, flexGrow:1, maxWidth: "25%"}}>
                                    <button className="btn" style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => { rulesShowInAppPopUpText?this.toggleRules():window.open(link, '_blank') }}>
                                        <Textfit mode="single" max={16} style={{overflowX: "hidden"}}>
                                            {rulesInAppButtonText}
                                        </Textfit>
                                    </button>
                                </div>
                                <div style={{display: (isMlbApp || this.props.passedEmail) && "none", width: "25%", height: 38, color: logOutButtonColor, borderColor: logOutButtonColor, border: "solid 1px", borderRadius: 5, flexGrow:1, maxWidth: 80}}>
                                    <button className="btn" onClick={() => { this.logout() }} style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}}>
                                        <Textfit mode="single" max={16}>
                                            LOG OUT
                                        </Textfit>
                                    </button>
                                </div>
                            </div>
                        }
                        <div className="user" style={{marginTop: 10}}>
                            <img onError={()=>this.setState({imageLoading: false})} onLoad={()=> this.setState({imageLoading: false})} src={tenantVariables.seasonLongTopLogo || tenantVariables.topLeftImage} alt="" style={{marginBottom:"0"}}/>
                        </div>
                        <div className="top-text">
                            <p className="top-text-mobile" style={{wordWrap: "break-word", fontWeight:700, color: tenantVariables.textColor}}>{whoWillWinText}</p>
                        </div>
                        <Carousel showArrows={true} showStatus={false} showIndicators={false} showThumbs={false} selectedItem={this.state.selectedItem}
                                  onChange={(e) => {
                                      this.setState({
                                          selectedItem: e,
                                      });
                                  }}>
                            {
                                racers.map(function(item,i){
                                    const itemId = item.id || item.key;
                                    if(itemId === this.state.campaignPick.pickid || itemId === this.state.highlightedPick){
                                        return <div
                                            key={i}
                                            id={itemId}
                                            className="grid-item"
                                            style={{background: tenantVariables.highlightPickColor, boxShadow: "none", border: "none"}}>
                                            <img onError={()=>this.setState({imageLoading: false})} onLoad={()=> this.setState({imageLoading: false})} src={item.gifSelectionImage} style={{maxWidth: 250, maxHeight: 250}} />
                                        </div>
                                    } else {
                                        return <div
                                            key={i}
                                            id={itemId}
                                            className="grid-item"
                                            onClick={()=>this.onClickCampaignPick(item)}
                                            style={{background: 'transparent', boxShadow: "none", border: "none"}}>
                                            <img onError={()=>this.setState({imageLoading: false})} onLoad={()=> this.setState({imageLoading: false})} src={item.gifSelectionImage} style={{maxWidth: 250, maxHeight: 250}} />
                                        </div>
                                    }
                                }, this)
                            }
                        </Carousel>
                        <style dangerouslySetInnerHTML={{
                            __html: [
                                '.carousel .control-next.control-arrow:before {',
                                'border-left: 32px solid ' + tenantVariables.highlightPickColor,
                                ';border-top: 16px solid transparent',
                                ';border-bottom: 16px solid transparent',
                                '}',
                                '.carousel .control-prev.control-arrow:before {',
                                'border-right: 32px solid ' + tenantVariables.highlightPickColor,
                                ';border-top: 16px solid transparent',
                                ';border-bottom: 16px solid transparent',
                                '}',
                                '.carousel .thumb.selected, .carousel .thumb:hover {',
                                '  border: 3px solid ' + tenantVariables.highlightPickColor,
                                '}'
                            ].join('\n')
                        }}>
                        </style>
                        <button className="btn btn-normal" style={{ display: this.state.highlightedPick ? "": "none", color: tenantVariables.secondaryColor, backgroundColor: tenantVariables.primaryColor, marginTop:"25px", width:"300px", fontSize:"1.5rem"}} onClick={()=>this.onSubmitCampaignAnswer()}>SUBMIT PICK</button>
                        {this.state.campaignPick.pickid &&
                            <p style={{marginTop: 10}}>
                                <b>YOUR SEASON-LONG SELECTION IS LOCKED IN!</b>
                            </p>
                        }
                    </div>
                </div>
                <Modal isOpen={this.state.modal} id="myOtherModal" style={{width: '90%'}}>
                    <ModalHeader>
                        <h4 style={{fontFamily: "Oswald", fontWeight: 700}}>{isMlbApp ?"Agree To The Rules & Regs To Play":"Enter Your Email To Submit Pick"}</h4>
                    </ModalHeader>
                    <ModalBody style={{textAlign:"center"}}>
                        <div className="container-out">
                            <div className="question-box question-form" style={{padding:'15px'}}>
                                <form onSubmit={this.handleSubmit} id="user-values-form">
                                    {!isMlbApp &&
                                        <div className="input-group">
                                            <input id="email" name="inputtedEmail" type="email" style={{fontFamily: "Oswald"}} className="form-control" onChange={this.handleChange} placeholder="Email"/>
                                        </div>
                                    }
                                    <div style={{display:!tenantVariables.noMandatoryTermsAndConditions?'table-row':"none",textAlign:'left'}}>
                                        <div style={{display: "table-cell", verticalAlign:'middle',padding:15}}>
                                            <input type="checkbox" ref="agree_to_rules_regs" style={{width:20,height:20}}/>
                                        </div>
                                        <div style={{display: "table-cell", verticalAlign:'middle', fontFamily: "Oswald"}}>
                                            <label htmlFor="agreeRulesAndRegsId"><strong><u><span onClick={()=>{link?window.open(link, '_blank'):this.toggleRules()}}>{rulesRegsText}</span></u></strong></label>
                                        </div>
                                    </div>
                                    <div style={{display:tenantVariables.collectOptIn?'table-row':"none",textAlign:'left'}}>
                                        <div style={{display: "table-cell", verticalAlign:'middle',padding:"0 15px 0 15px"}}>
                                            <input type="checkbox" ref="agree_to_promotion" defaultChecked={!!tenantVariables.collectOptIn} style={{width:20,height:20}}/>
                                        </div>
                                        <div style={{display: "table-cell", verticalAlign:'middle', fontFamily: "Oswald"}}>
                                            <label htmlFor="agreeToPromotionId">{promotionText}</label>
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-play" onClick={() => { this.submitEmail(); }} style={{color: tenantVariables.secondaryColor, backgroundColor: tenantVariables.primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>SUBMIT</button>
                                </form>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter style={{borderTop:'none'}}/>
                </Modal>
                <Modal isOpen={this.state.modalRules} style={{width: '90%'}} id="rulesModal">
                    <ModalHeader style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {rulesShowInAppPopUpHeader}
                    </span>
                    </ModalHeader>
                    <ModalBody>
                        <center className="container-out">
                            <div className="question-box question-form">
                                <p style={{width:"100%",height:200, overflow:"scroll",padding:10, borderBottom:"1px solid black", borderTop: "1px solid black", fontWeight:100, fontSize:14}} dangerouslySetInnerHTML={{ __html:rulesShowInAppPopUpText}}/>
                                <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>Dismiss</button>
                            </div>
                        </center>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.modalSupport} style={{width: '90%'}} id="rulesModal">
                    <ModalHeader style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {supportHeader}
                    </span>
                    </ModalHeader>
                    <ModalBody>
                        <center className="container-out">
                            <div className="question-box question-form">
                                <p style={{width:"100%",overflow:"scroll",fontWeight:100, fontSize:14, marginBottom: 0}} dangerouslySetInnerHTML={{ __html:supportText}}/>
                                <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleSupport(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', fontSize:'1.3 rem'}}>Dismiss</button>
                            </div>
                        </center>
                    </ModalBody>
                </Modal>
            </div>
        )
    }

    render() {
      const tenantVariables = this.props.variables || {};
      if (this.state.loading === true) {
            return (
                <Loading primaryColor={tenantVariables.primaryColor} loading={this.state.loading} backgroundImage={tenantVariables.backgroundImage}/>
            )
        }
      let renderMainScreen = this.renderSeasonLongScreen();

      return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + tenantVariables.backgroundImage + ")"}}>
            {renderMainScreen}
          </div>
      )
    }
}

export default Season_Long;
